import { LiveChatWidget, EventHandlerPayload } from "@livechat/widget-react"
import { GROUP_ID, LIVE_CHAT_LICENSE } from "../service/env"
import { useContextAuthManager } from "../context/auth.context"

const LiveChat = () => {
  const { isLogIn, username } = useContextAuthManager()
  function handleNewEvent(event: EventHandlerPayload<"onNewEvent">) {
    console.log("LiveChatWidget.onNewEvent", event)
  }

  if (!isLogIn) {
    return null
  }

  return <LiveChatWidget license={LIVE_CHAT_LICENSE} group={GROUP_ID} customerName={username} visibility="maximized" onNewEvent={handleNewEvent} />
}

export default LiveChat
